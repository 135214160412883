.carouselCoin .carousel-control-next-icon {
  background-image: url("../../../assets/icons/arrow-right-green.png");
}
.carouselCoin .carousel-control-prev-icon {
  background-image: url("../../../assets/icons/arrow-left-green.png");
}
.unique .carousel-control-next-icon {
  display: none !important;
}
.unique .carousel-control-prev-icon {
  display: none !important;
}

@media (min-width: 320px) {
  .carrousel {
    width: auto;
  }
  .carrousel .carousel-inner {
    height: 165px;
  }
  .carrousel .carousel-item {
    height: 100%;
    padding: 20px;
  }
}
@media (min-width: 380px) {
  .carrousel .carousel-inner {
    height: 200px;
  }
}
@media (min-width: 520px) {
  .carrousel .carousel-inner {
    height: 250px;
  }
}
@media (min-width: 769px) {
  .carouselCoin {
    width: auto;
    height: 380px;
    margin: 0 auto;
  }
  #carouselIndex .carouselCoin {
    height: auto;
    width: auto;
  }
  .carouselCoin .carousel-inner {
    padding: 0 16px;
  }
  .carouselCoin .carousel-control-next,
  .carouselCoin .carousel-control-prev {
    background: none;
  }
  .carouselCoin .carousel-indicators {
    visibility: hidden;
  }

  /* carrousel forEach */

  .carrousel .carousel-inner {
    height: 346px;
    padding: 0 25px;
  }
}
@media (min-width: 890px) {
  .carouselCoin {
    width: 465px;
  }
  .carrousel {
    width: auto;
  }
}
