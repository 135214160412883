.sectAlert {
  padding: 0 5%;
  background-color: #f7f7f7;
}
.inputDate {
  height: 54px;
}

.text-date {
  font-size: 1rem;
  line-height: 1.3rem;
  color: #9a9a9a;
  font-family: "Roboto", sans-serif;
}
.lblCheck {
  padding: 4px 0 0 2px;
  font-size: 15px;
}
@media (min-width: 1200px) {
  .divDate {
    margin-right: 270px !important;
  }
  .divCelDD {
    margin-right: 230px !important;
  }
  .divButton {
    margin-top: 0 !important;
  }
}
@media (min-width: 1024px) {
  .inputDate {
    margin: -9px 0;
    height: 40px;
  }
  .divDate {
    width: auto !important;
  }
  .divCot {
    width: 300px !important;
  }
}
@media (min-width: 768px) {
  .divReceber {
    display: flex;
    flex-wrap: wrap;
  }
  .sectAlert {
    display: flex;
    flex-wrap: wrap;
    padding: 0 18px;
  }
  .eachAlert {
    margin-right: 10px;
    width: 250px;
  }
  .divDDD {
    width: 70px;
  }
  .divCel {
    width: 185px;
  }
  .checkInput {
    width: 18px !important;
    height: 18px !important;
  }
  .divNome {
    place-self: flex-end;
  }
  .formCotacao {
    width: 250px;
  }
}
@media (max-width: 767px) {
  .divButton {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 320px) {
  .divCheck {
    display: flex;
  }
  .checkInput {
    width: 30px;
    height: 20px;
  }
}
