.bannerGlobal {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.saibaMaisBtn {
  z-index: 1;
  width: 175px;
  height: 50px;
  position: absolute;
  bottom: 158px; 
  left: 53%;
  transform: translateX(-50%);
  padding: 0;
  background-color: transparent; 
  border: none;
  cursor: pointer;
}

@media(min-width: 500px) {
  .saibaMaisBtn {
    bottom: 158px; 
  }
}

@media(min-width: 520px) {
  .saibaMaisBtn {
    bottom: 133px; 
  }
}

@media(min-width: 700px) {
  .saibaMaisBtn {
    width: 250px;
    left: 51%; 
  }
}

@media(min-width: 910px) {
  .bannerImg {
    height: 500px;
  }

  .saibaMaisBtn {
    bottom: 45%; 
    left: 69%
  }
}

@media(min-width: 1000px) {
  .bannerImg {
    height: 600px;
  }

  .saibaMaisBtn {
    bottom: 45%; 
    left: 65%
  }
}

@media(min-width: 1200px) {
  .saibaMaisBtn {
    width: 350px;
    height: 70px;
    bottom: 43%;
    left: 67%;
  }
}

@media(min-width: 1300px) {
  .saibaMaisBtn {
    left: 66%;
  }
}

@media(min-width: 1400px) {
  .saibaMaisBtn {
    width: 430px;
    height: 90px;
    bottom: 33%; 
    left: 69%
  }
}

@media(min-width: 1500px) {
  .saibaMaisBtn {
    left: 67%
  }
}

@media(min-width: 1700px) {
  .saibaMaisBtn {
    left: 65%
  }
}

@media(min-width: 2000px) {
  .saibaMaisBtn {
    left: 63%
  }
}

@media(min-width: 2300px) {
  .saibaMaisBtn {
    left: 61%
  }
}

@media(min-width: 2500px) {
  .saibaMaisBtn {
    left: 60%
  }
}
