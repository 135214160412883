.menuFinalizacao {
  background-color: #f7f7f7;
  font-family: "Roboto", sans-serif;
}

.menuFinaliza {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.menuFinaliza > h1 {
  margin-top: 30px;
  color: var(--green);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
}

.menuFinaliza > p {
  color: var(--light);
  font-weight: 500;
}

@media (min-width: 1000px) {
  .menuFinalizacao {
    padding: 30px;
  }
}

@media (min-width: 1220px) {
  .formularios {
    display: flex;
    justify-content: center;
  }
}
