.aCard {
  text-decoration: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.aCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 2em rgba(63, 61, 61, 0.11);
  text-decoration: none !important;
}

.card-li {
  list-style: none;
}
