h3 {
  margin: 0;
}

h3 {
  font-size: 20px;
}

button {
  border: none;
  background: none;
}

.principal {
  background-color: #f7f7f7;
  font-family: CenturyGothic;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.carrinho {
  width: 87%;
  max-width: 100%;
  background-color: #f7f7f7;
  padding: 24px 15px;
}

.itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.itens > div {
  display: flex;
}

.itens > div > p {
  margin-left: 5px;
}

.menuPrincipal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.container-img > img {
  width: 70px;
  height: 50px;
  border-radius: 5px;
  margin: 5px 0;
}

.detalhes > .remover {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #758080;
}

.menuCompras {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
  color: #758080;
}

.div1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.div1 > div {
  margin-left: 5px;
}

.div2 {
  width: 180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.detalhes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: #758080;
}

.detalhes > button {
  font-size: 14px;
  font-weight: 600;
  color: #758080;
}

.codigoPromocional {
  display: flex;
  margin-top: 5px;
}

.codigoPromocional > input::placeholder {
  padding: 10px;
  font-weight: 500;
  color: #848b9295;
}

.next {
  background-color: var(--green);
  width: 30px;
  border-radius: 0 2px 2px 0;
}

.contato {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.contato > p {
  margin-right: 9px;
}

.contato > a {
  text-decoration: none;
  color: #007354;
}

.informacoesPedido {
  > p {
    color: red;
    font-size: 12px;
  }
}

.interesse {
  color: var(--green);
  font-weight: 600;
  margin-bottom: 10px;
}

.informacoesPedido {
  width: 100%;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

.controle-quantidade {
  display: flex;
  align-items: center;
  justify-content: center;
}

.controle-quantidade button {
  color: var(--green);
  background: transparent;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.controle-quantidade button:active {
  transform: scale(0.95); 
}

.controle-quantidade button:disabled {
  background-color: #ddd; 
  color: #888;
  cursor: not-allowed;
  box-shadow: none;
}

@media (min-width: 400px) {
  .menuCompras {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
  }

  .div1 > img {
    width: 102px;
    height: 70px;
  }
}

@media (min-width: 750px) {
  .menuPrincipal {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .menuCompras {
    margin: 0;
  }

  .div1 > div {
    margin-left: 15px;
  }

  .informacoesPedido {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1000px) {
  .div1 > img {
    width: 150px;
    height: 85px;
  }

  .div2 {
    width: 195px;
  }

  .menuCompras,
  .detalhes > button {
    /* font-size: 18px; */
  }
}

@media (min-width: 1240px) {
  .principal {
    flex-direction: row;
    align-items: flex-start;
  }

  .informacoesPedido {
    width: 50%;
  }
}

@media (min-width: 1400px) {
  .menuPrincipal {
    justify-content: space-around;
  }

  .itens {
    margin: 0 0 0 50px;
  }

  .detalhes,
  .div1 {
    margin-left: 50px;
  }

  .dividerCarrinho {
    margin-left: 50px;
  }

  .buttons {
    margin-left: 50px;
  }
}

@media (min-width: 1700px) {
  .itens {
    margin: 0 0 0 100px;
  }

  .detalhes,
  .div1 {
    margin-left: 100px;
  }

  .buttons {
    margin-left: 100px;
  }

  .dividerCarrinho {
    margin-left: 100px;
  }
}

@media (min-width: 1900px) {
  .itens {
    margin: 0 0 0 150px;
  }

  .detalhes,
  .div1 {
    margin-left: 150px;
  }

  .dividerCarrinho {
    margin-left: 150px;
  }

  .buttons {
    margin-left: 150px;
  }
}

@media (min-width: 2100px) {
  .menuPrincipal {
    justify-content: space-evenly;
  }

  .itens {
    margin: 0 0 0 200px;
  }

  .detalhes,
  .div1 {
    margin-left: 200px;
  }

  .dividerCarrinho {
    margin-left: 200px;
  }

  .buttons {
    margin-left: 200px;
  }
}
