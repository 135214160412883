@media (max-width: 768px) {
  .f-green {
    font-size: 10px;
  }
}
.htitle {
  font-size: 1.2rem;
  font-weight: 500;
}
@media (max-width: 719px) {
  .cTable {
    font-size: 10px;
  }
}
#rentability .MuiTabs-flexContainer {
  display: flex;
  justify-content: space-around;
}
.divRent {
  height: 230px;
  padding: 0;
  overflow: auto;
}
