#central,
#loja,
#ouro,
#parceiro,
#assessoria,
#marketing,
#parceiro,
#sugestoes,
#denuncia,
#reclamacao,
#ouvidoria ,
#trabalhe-conosco,
#app{
  scroll-margin-top: 120px;
}
