.sectAlert {
  padding: 0 5%;
  background-color: #f7f7f7;
}
.lblCheck {
  padding: 4px 0 0 2px;
  font-size: 15px;
}
.obs {
  font-size: 12px;
  color: red;
}
.divSuccess{
  width: 100%;
}
@media (min-width: 320px) {
  .divCheck {
    display: flex;
  }
  .checkInput {
    width: 30px;
    height: 20px;
  }
  .divSuccess{
    text-align: center;
  }
}
@media (max-width: 767px) {
  .divButton {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .sectAlert {
    display: flex;
    flex-wrap: wrap;
    padding: 0 18px;
  }
  .eachAlert {
    margin-right: 10px;
    width: 250px;
  }
  .divDDD > div {
    width: 60px;
  }
  .divCel {
    width: 185px;
  }
  .checkInput {
    width: 18px;
    height: 18px;
  }
  .divNome {
    place-self: flex-end;
  }
  .divSuccess{
    text-align: start;
  }
}
@media (min-width: 1024px) {
  .divCot {
    width: 300px;
  }
}
@media (min-width: 1200px) {
  .divDateAlert {
    width: auto;
  }
  .divCelDD {
    margin-right: 230px;
  }
  .divButton {
    margin-top: 0;
  }
  .divContato {
    margin-right: 50px;
  }
}

@media (min-width: 1300px) {
  .divContato {
    margin-right: 90px;
  }
}
