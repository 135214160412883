/* 
==========================================================
App.css 
========================================================== 
*/
/*
=============================
  Ourominas page styling
=============================
*/

/* 
  Font family import from google fonts
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "react-alice-carousel/lib/alice-carousel.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --green: #004133;
  --brown: #cda155;
  --gray: #383838;
  --light-gray: #a1a1a1;
}

.section1 {
  height: 412px;
  background-image: url(../src/assets/bg-0001.png);
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
  margin-top: -20 px;
  margin-left: -20 px;
  animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3 px;
}

.container1 {
  width: "100%";
  height: 412px;
  margin: 0 15%;
}

.row1 {
  display: flex;
  flex-direction: row;
  padding: 20px 0 30px 0;
}

.sec-col1 {
  height: 412px;
  width: 50%;
  display: flex;
}

.img1 {
  height: 70%;
  width: 40%;
  background-image: url(../src/assets/om-0001.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  position: relative;
  top: 10%;
  left: 2%;
}

.sec-img2 {
  width: 60%;
}

.cont-img2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 50%;
}

.sub-con-img2 {
  width: 50%;
  height: 60%;
  margin-right: 5px;
  background-image: url(../src/assets/cartao_OM-removebg-preview.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.sub-con-img3 {
  width: 50%;
  height: 100%;
  background-image: url(../src/assets/app_OM.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
}

.cont-img4 {
  width: 100%;
  height: 50%;
  background-image: url(../src/assets/om-0006.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

/* section column 2 */

.sec-col2 {
  padding-left: 5%;
  padding-top: 5%;
}

.sec-col2 h1 {
  z-index: 12;
  font-family: Arial, Helvetica, sans-serif;
  height: auto;
  color: var(--green);
  text-decoration: none;
  white-space: normal;
  width: 436 px;
  min-height: 0 px;
  min-width: 0 px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 50px;
  letter-spacing: 7px;
  font-weight: 600;
  font-size: 38px;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0 px, 0 px);
  visibility: visible;
}

.sec-col2 p {
  z-index: 18;
  font-family: Arial, Helvetica, sans-serif;
  height: auto;
  color: var(--gray);
  text-decoration: none;
  white-space: normal;
  width: 404 px;
  min-height: 0 px;
  min-width: 0 px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 28px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 15px;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0 px, 0 px);
  visibility: visible;
  margin: 2% 0 3%;
}

.sec-col2 div {
  display: flex;
  justify-content: "space-between";
  gap: 10px;
  width: 100%;
}

.ouro,
.cambio,
.outros {
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  text-align: left;
  /* line-height: 40px; */
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  border: 0px;
}

.ouro {
  padding: 0 40px;
  background-color: var(--brown);
}
.cambio {
  padding: 0 24px;
  background-color: var(--green);
}

.outros {
  padding: 0 20px;
  background-color: var(--light-gray);
  width: 180px;
}

.play-button {
  width: 42px;
  background-image: url(../src/assets/botao-play.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  border: none;
}
.MuiFormHelperText-root {
  margin: 0px !important;
  background-color: #f7f7f7;
  color: red !important;
  padding: 1px 5px;
}
.aLink {
  color: #a9a9a9;
  text-decoration: none;
}
.nav-link {
  font-weight: bolder;
}
html {
  scroll-behavior: smooth !important;
}
.navExport {
  padding: 0.25rem 1rem;
}
.navExport:hover {
  color: #1e2125;
  background-color: #e9ecef;
}
.navExport > a {
  padding: 0 !important;
  font-weight: unset;
  color: #1e2125 !important;
}
@media (min-width: 1024px) {
  .navExport .show {
    z-index: 90;
    position: absolute;
    left: 99%;
    top: -30%;
    /* border-left: 0; */
    border-radius: 0 5px 5px 0;
  }
}
li {
  line-height: 1.8em;
}
/* 
====================================
  Media Query
====================================
  To make responsive for all devices
====================================
*/

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .row1 {
    display: flex;
    flex-direction: column;
  }
  .sec-col1 {
    width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  .section1 {
    height: 900px;
  }
  .row1 {
    display: flex;
    flex-direction: column;
  }
  .sec-col1 {
    width: 100%;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
  .img1 {
    top: 1%;
  }
  .img4 {
    height: 40%;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
