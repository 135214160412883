#lojas p {
  text-align: initial;
}
.tabPanelLoja {
  border: 2px solid;
  padding: 0 15px;
}
.LineSpan {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.LineTel {
  display: flex;
  gap: 10%;
  flex-wrap: wrap;
  line-height: 2;
}
.lojaAutorizada, .lojaPropria {
  background-color: #ccff33;
  padding: 4px;
  border-radius: 5px;
}

@media (max-width: 520px) {
  .LineTel {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 760px) {
  .tabPanelLoja {
    margin-top: 20px;
  }
}

 #lojas button:focus{
  background-color: rgba(62, 62, 62, 0.201) !important;
}

#lojas .MuiTabs-indicator{
  display: none !important;
}

.tabItems:hover {
  background: #ccff33;
}