/* 
==========================================================
App.css 
========================================================== 
*/
/*
=============================
  Ourominas page styling
=============================
*/

/* 
  Font family import from google fonts
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:ital,wght@0,100;0,400;1,100&display=swap");

:root {
  --green: #004133;
  --brown: #cda155;
  --gray: #383838;
  --light-gray: #a1a1a1;
  --light: #9a9a9a;

  --primary-white: #fff;
  --primary-yellow: #fcca03;
  --primary-dark: #333333;
  --primary-light: #a4a4a4;
  --primary-red: #ef4035;
  --primary-red-hover: #f33501;
}

/* header */
.iconShopp {
  color: #004133;
}
.iconShopp:hover {
  color: #0041339a;
}
.header {
  background-color: #fff;
  padding: 0 !important;
}

.header-icons {
  color: var(--green);
  font-size: 1rem !important;
}

nav {
  z-index: 2;
}
.logo {
  width: 3.25rem;
  max-width: 12.25rem;
  padding: 0 !important;
  height: 3rem !important;
}
.c-pointer{
  
  cursor: pointer;
}
/* Navbar links */
.links {
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.0375rem;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--green) !important;
  margin: 0 0.15rem;
  border-bottom: 0.1875rem solid transparent;
  height: 5rem !important;
  padding-top: 3.3% !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif;
}

/* Navbar links get border-top when click */
.links:active,
.links:focus {
  border-bottom: 0.1875rem solid hwb(0 90% 10%);
}

.links:hover {
  border-bottom: 0.1875rem solid #e5e5e5;
}

/* Section 4 */
.row4 {
  padding: 5% 0;
  border-top: 1px solid var(--green);
}

.sec-col4 h1,
.Alert-cont-h h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 5 px;
  color: var(--brown);
}

.sec-col4 p,
.Alert-cont-h p {
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--light);
  font-family: "Roboto", sans-serif;
}

.sec-col4 h3 {
  font-size: 14px;
  color: var(--green);
  font-family: "Roboto", sans-serif;
}

.form-box {
  padding: 10px 20px 5px 20px;
  background-color: #fafcfe;
  border-bottom: 2px solid var(--light-gray);
}

.form-cal {
  display: flex;
  height: 60px;
  gap: 10px;
  margin: 10px 0 0 0;
  padding-top: 10px;
}

.form-cal h4 {
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--light);
  font-family: "Roboto", sans-serif;
}

.dollar {
  width: 25%;
  align-self: center;
}

.calulated {
  width: 20%;
  height: 90%;
  padding: 10px 22px;
  align-self: center;
  border: 4px solid #fff;
  background-color: #fff;
}

.cal-btn {
  display: flex;
  gap: 10px;
  padding-top: 5px;
}

.cal-btn button {
  color: #fff;
  background-color: var(--brown);
  letter-spacing: 0px;
  font-weight: bold;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  padding: 0 20px;
  height: 30px;
  border: 0;
  border-radius: 4px;
}

.cal-container {
  padding: 10px 20px 5px 20px;
  background-color: #fafcfe;
}

.calcular {
  padding: 10px 12%;
}

.calcular h5 {
  color: var(--light);
  font-size: 25px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.calcular-btn {
  background-color: var(--green);
  color: #fff;
  width: 100%;
  height: 40px;
  font-family: "Poppins", sans-serif;
  border: 1px solid var(--green);
  border-radius: 4px;
  font-size: 12px;
}

.ratio {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #19725f;
  border: 3px solid black;
}

.cal-ratio-cont {
  width: 50%;
}

.-ratio {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  padding: 0px 10%;
}

.ratio-cont {
  width: 80px;
  background-color: #19725f;
  height: 30px;
}
.ratio-cont2 {
  width: 80px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
}

.ratio-cont-sub {
  height: 10px;
  background-color: var(--green);
}
.ratio-cont-sub2 {
  height: 30px;
  background-color: #19725f;
}

.ratio-cont2 h3 {
  text-align: right;
  background-color: #fafcfe;
}

.de2009 {
  margin-top: 20px;
}

/* ================== sec-col4-2 ======================= */

.divider {
  border: 1px solid var(--light);
}

.tab1 {
  padding: 0 10% 10px 10%;
}

.tab1 h3 {
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--light);
  font-family: "Roboto", sans-serif;
}

/* Section 3 */

.row3 {
  padding: 20% 0 10% 0;
}

.col-3,
.htitle {
  color: #cda153;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
}

.graph-view {
  width: 100%;
}
.graph-view img {
  width: 100%;
}

/* ============TAB3============== */
.icon-img {
  padding: 10px;
}

/* 
  Note: Child h3 and p of Alert-cont is styles at top of the code
*/

.Alert-cont-h {
  padding: 3% 2% 1% 2%;
}

.alert-cont {
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
  height: 200;
  padding: 0 10px 20px 10px;
}

.currency-forms {
  padding-top: 20px;
}

.currency-forms h2 {
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--light);
  font-family: "Roboto", sans-serif;
}

.layout-form {
  padding-left: 10px;
}

.form-layout {
  padding: 0 15px;
  margin-bottom: 10px;
}

.make-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*  =========================
Section5 Links
==========================*/

.section5 {
  background-color: var(--green);
}

.row5 {
  padding: 10% 0;
}

.col-5 img {
  width: 23%;
}

.col-5 div {
  padding: 15px;
}

.col-5 h1 {
  color: #ffffff;
  font-size: 14px;
  padding-bottom: 8px;
}

.col-5 h2 {
  color: #ffffff;
  font-size: 14px;
}

.col-5 li {
  list-style: none;
  color: #a9a9a9;
  padding-left: 0px;
  font-size: 14px;
  padding-bottom: 7.5px;
  font-family: "Poppins", sans-serif;
}

.icons {
  color: #fff;
  font-size: 11px;
  position: relative;
  right: 15px;
  bottom: 15px;
}

/* ================
Footer section
*/

.footer-sec {
  background-color: #eeeeee;
  /* border-bottom: 1px solid var(--gray); */
}

.footer-row {
  padding: 1% 0 3% 0;
}

.footer-row-l {
  padding: 2% 0;
}

.footer1 h1 {
  color: #004133;
  font-size: 26px;
  font-weight: 500;
}

.footer1 p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  padding-bottom: 15px;
  color: var(--gray);
}

.pFooter {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  padding-bottom: 15px;
  color: var(--gray);
}

.footer1 div {
  display: flex;
  flex-direction: column;
}

.footer1 input,
.selectFooter {
  background-color: #fff;
  border: 1px solid #d8d8d8;
  color: #9b9b9b;
  padding: 6px 22px;
  line-height: normal;
  font-size: 15px;
  font-weight: 400;
  height: 40px;
  margin-bottom: -20 px;
  margin: 1% 0;
}

.send-footer {
  background-color: var(--green);
  color: #fff;
  width: 25%;
  height: 40px;
  font-family: "Poppins", sans-serif;
  border: 1px solid var(--green);
  border-radius: 4px;
  font-size: 12px;
  margin-top: 2%;
}
.divFooter2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.divFooter2 img {
  width: 63px;
}
.divF2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-right: 10%;
}
.divF2 img {
  width: 100px;
}

.footer-row-ls {
  display: flex;
  justify-content: space-between;
  padding: 2% 0;
}
.checkFooter {
  width: 18px !important;
  height: 18px !important;
}
.f1 {
  display: flex;
  justify-content: center;
}

.p3 {
  text-align: center;
}

.f3 {
  display: flex;
  justify-content: center;
}
/* section3.js  */
.alert-form {
  width: 40vw;
}
.green {
  background-color: var(--green) !important;
}
.f-green {
  color: var(--green) !important;
}
.btn-green-hover:hover {
  background-color: #002c23;
}
.btn-gold-hover:hover {
  background-color: #9b732f;
}
.btn-grow-hover:hover {
  background-color: #616060;
}
.dropdown-toggle::after {
  display: none;
}
.navDrop {
  padding-bottom: 8px;
  margin-bottom: 3px;
  color: var(--green) !important;
}
#navbarScrollingDropdown {
  color: var(--green) !important;
}
.r-image {
  width: 100px !important;
}
.navLink,
.navLink:hover {
  text-decoration: none;
  color: #1e2125;
}
.subTitle {
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--light);
  font-family: "Roboto", sans-serif;
  margin: 0;
}

@media (max-width: 991px) {
  .navbar-nav .dropdown-menu {
    position: absolute;
    border: 0;
    margin-top: 0;
    width: 100%;
  }
}
@media (max-width: 576px) {
  /* section3.js  */
  .alert-cont {
    display: flex;
    flex-direction: column;
  }
  .alert-form {
    width: 80vw !important;
  }
  .dropdown-item {
    padding-left: 0;
  }
}
.css-1kvq9rr {
  height: 540px !important;
}
/* tab.js */
@media (max-width: 375px) {
  .w80 {
    width: 80%;
  }
}
/* media query */
@media (max-width: 425px) {
  .tab1 {
    padding: 0;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .formaContato {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  #vertical-tab-0 {
    padding-right: 20% !important;
  }

  #tabCoins .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-around;
  }
}

@media (max-width: 767px) {
  .send-footer {
    margin: auto;
    margin-top: 0.5rem;
  }
}

@media (max-width: 1200px) {
  .send-footer {
    width: 150px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  /* section3.js */
  .alert-cont {
    display: flex;
    flex-wrap: wrap;
  }
  .currency-forms {
    width: 50%;
    height: 100px;
  }
  .container1 {
    margin: 0 !important;
  }
}

@media (min-width:960px){
  .width-30{
    width: 30% !important;
  }
}

.tabCoins__button:hover{
  background-color: rgba(62,62,62,0.201);
}
.social-icons > svg, #icon-threads{
  margin-right: 10px;
  font-size: 30px;
  background: #dbd9d6;
  color: black;
  padding: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;  
 
  transition: font-size 0.5s cubic-bezier(.63,.37,.55,.9), color 0.3s cubic-bezier(.63,.37,.55,.9), background 0.5s cubic-bezier(.63,.37,.55,.9);

}

@media only screen and (min-width: 575px) and (max-width: 991px){
  .social-icons > svg, #icon-threads{
    margin: 10px 10px 10px 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1400px){
  .social-icons > svg, #icon-threads{
    margin: 10px 10px 10px 0px;
  }
}
.social-icons > svg:hover, #icon-threads:hover{
  font-size: 40px;
  color: #fff;
  background: rgba(95, 94, 94, 0.5);
}

#icon-threads{
  width: 40px;
  height: 40px;
}

