@media(min-width: 320px){
    .sect__sobre{
        display: flex;
        justify-content: center;
        > img{

            width: 80vw;
            margin: 0 auto;
        }
    }
}
@media (min-width: 1200px) {
    .p-div {
        padding: 0px 0px 0px 68px;
    }
    .sect__sobre{
        > img{

            width: 50vw;
            margin: 0 auto;
        }
    }
}