.alertPosition {
  top: 15% !important;
}
.bgAlert {
  background-color: #d1cecef1;
}
.btnSelos {
  align-self: center !important;
}
.awtra{
  width: 120px !important;
}
.buttonSelos img {
  width: 25px;
}
.imgGPTW {
  width: 150px !important;
}
.abracam {
  width: 90px !important;
}
.w-70 {
  width: 70px !important;
}

@media (max-width: 768px) {
  .alertPosition {
    width: 66vh;
  }
}
@media (max-width: 600px) {
  .alertPosition {
    top: 7% !important;
    width: 50vh;
  }
  .bgAlert p {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .alertPosition {
    top: 10% !important;
    width: 37vh;
  }
}

.none{
  visibility: hidden;
}
#alertCertificado{
  display: block;
  max-height: 80vh;
  overflow-y: auto;
  scroll-behavior: auto;
}

/* .isos{
  width: 140px !important;
} */