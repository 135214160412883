@media (max-width: 999px) {
  .menuU {
    display: none;
  }
}

@media (min-width: 768px) {
  .carouselMenu {
    display: none;
  }

  .menuU {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 100%;
    height: 100%;
    margin: 35px auto;
  }

  .button-menuU {
    border: none;
    background-color: var(--bs-body-bg);
    font-weight: 500;
    color: #a9a9a9;
    font-size: 16px;

    > p {
      margin-bottom: 0;
    }
  }

  .button-menuU:hover {
    color: #004133;
  }

  .etapasS {
    margin: 8px;
    margin: 0;
  }
}

@media (min-width: 1000px) {
  .menuU {
    justify-content: space-evenly;
  }
}

.breadcrumb-item{
  font-weight: 500;
  color: #a9a9a9 !important;
  font-size: 16px;
}

.breadcrumb-item:hover{
  color: #004133 !important;
}
