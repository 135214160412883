@media(min-width: 1024px){
    .th{
        width: 240px;
    }
}

@media(min-width: 1200px){
    .th{
        width: 280px;
    }
}
.carousel-vitrine {
    width: 100%;
    .carousel-inner {
        padding: 0;
    }

    .carousel-control-prev,
    .carousel-control-next,
    .carousel-indicators {
        display: none ;
    }
    .carousel-item {
        transition: transform 1s ease-in-out; /* Torna a transição entre slides mais lenta (2s) */
        margin: 0;
        padding: 0;
        background-color: white;
    }
}

