*{
  user-select: none;
}
@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url(./fonts/Gotham/GothamBold.otf) format("truetype");
}
@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(./fonts/Gotham/GothamBook.otf) format("truetype");
}
@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
    url(./fonts/Gotham/GothamMedium.otf) format("truetype");
}
@font-face {
  font-family: "LibertinusBold";
  src: local("LibertinusBold"),
    url(./fonts/Gotham/LibertinusSerifBold.otf) format("truetype");
}
@font-face {
  font-family: "LibertinusRegular";
  src: local("LibertinusRegular"),
    url(./fonts/Gotham/LibertinusSerifRegular.otf) format("truetype");
}
@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic"),
    url(./fonts/Gotham/CenturyGothic.TTF) format("truetype");
}
@font-face {
  font-family: "CenturyGothicBold";
  src: local("CenturyGothicBold"),
    url(./fonts/Gotham/CenturyGothicBold.TTF) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-text {
  font-family: "Roboto", sans-serif;
  font-family: "Roboto", sans-serif;
  color: #383838;
  font-size: 18px;
  line-height: 34px;
}
.send-to {
  background-color: var(--green);
  color: #fff;
  width: 260px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  border: 1px solid var(--green);
  border-radius: 4px;
  font-size: 12px;
  justify-self: center;
}
.btnFooter, .btnFooter a {
  margin: 0;
  padding: 0;
  text-align: start;
  font-size: 14px !important;
  border: 0;
  background-color: unset;
  color: #a9a9a9;
  text-decoration: none;
}
.btnFooter:hover, .btnFooter a:hover {
  color: #fff;
}
html {
  scroll-behavior: smooth !important;
}
