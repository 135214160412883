.divBanner {
  background-color: #004032ff;
  height: 600px;
  align-items: center;
}

.divBannerGlobal {
  background-color: #00391D;
  width: 100%;
}

.divBannerBlack {
  background: #001409;
  width: 100%;
}

.carouselBanner{
  width:100% !important;
  height:100%;
}
.carouselBanner > .carousel-inner {
  padding: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background-size: contain;
  width: 50px;
  height: 50px;
  transform: translateY(-50%);
  display: block;
  filter: invert(1);
}

.disable-prev .carousel-control-prev, 
.disable-next .carousel-control-next {
  display: none !important;
}

.divBanner .row {
  height: 100%;
  align-items: center;
  display: flex;
  color: #fff;
}
.divBanner h2 {
  font-family: GothamBold;
}
.divBanner .sub {
  font-family: GothamBook;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.745);
}
.divBanner p {
  font-family: GothamMedium;
}
.notCell{
  display: none;
}
.btnBanner {
  color: #fff;
  border: #19725f;
  border-radius: 20px;
  background-color: #19725f;
  font-weight: 600;
  text-transform: uppercase;
  padding: 6px 40px;
  margin-bottom: 15px;
  font-family: GothamMedium;
}
.btnBanner:hover {
  background-color: #155445;
}

@media (min-width: 1201px) {
  .divBanner .colText {
    padding-right: 210px;
  }
}
@media (max-width: 1200px) {

  .divBanner .divImg {
    display: flex;
    justify-content: center;
  }
  .divBanner .colText {
    padding-right: 70px;
  }
}
@media (max-width: 1000px) {
  .divBanner {
    height: 500px;
  }
  .divBanner img {
    width: 400px;
  }
  .divBanner .colText {
    padding-right: 50px;
  }
}
@media (max-width: 769px) {
  .divBanner {
    height: 700px;
  }
  .divBanner img {
    width: 400px;
  }
  /* .carouselBanner > .carousel-control-next, .carousel-control-prev{
    display: none !important;
  } */
}
@media (max-width: 481px) {
  .divBanner img {
    width: 370px;
  }
  .btnBanner {
    padding: 5.5px 20px;
    margin-bottom: 15px;
  }
}
@media (max-width: 370px) {
  .divBanner img {
    width: 315px;
  }
}
.carouselBanner{
  width:100% !important;
  height:100%;
}
.carouselBanner > .carousel-inner {
  padding: 0;
}

@media(min-width: 520px){
  .buttons_black{
    width: 417px ;
    justify-content: start;
  }
}

@media(min-width: 1024px){
  .buttons_black{
    width: 480px ;
    justify-content: space-around;
  }
  .buttons_black__item:nth-child(n){
    min-width: 125px;
  }
  .buttons_black__item:nth-child(4){
    width: 160px;
    margin-top: 10px;
  }
}
