@media (max-width: 465px) {
  .mobile-invisible {
    display: none !important;
  }
}
.mobile-invisible {
  display: none !important;
}
@media (max-width: 768px) {
  .f-green {
    font-size: 10px;
  }
}
.htitle {
  font-size: 1.2rem;
  font-weight: 500;
}
@media (max-width: 719px) {
  .cTable {
    font-size: 10px;
  }
}

@media (max-width: 400px) {
  .cTable {
    font-size: 8px;
  }
}
