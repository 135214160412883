li {
  line-height: 1.4em;
}
.wv-100 {
  width: 100vw !important;
}
.c-init {
  width: 100%;
  display: flex;
  padding: 0;
}
.p-text {
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: #383838;
  line-height: 1.4em;
  text-align: justify;
}
.div-line {
  text-align: center;
  margin-top: 1rem;
}
.div-line img {
  height: 1.5px;
  width: 350px;
}
summary {
  font-weight: bolder;
  padding: 5px 0;
  text-align: start;
}
summary:hover {
  color: #9af657;
}
.div-group2 .div-groups ol,
ul {
  padding-left: 1rem;
}
.div-image {
  margin-top: 1.5rem;
}

.espaco {
  margin: 15px 50px;
}

.organograma {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}

.vendah {
  img {
    width: 100%;
    object-fit: contain;
  }
}

@media (min-width: 320px) {
  .div-groups {
    margin: 10px 0;
    width: 100%;
    border: 2px solid #9a9a9a;
    padding: 20px 15px;
    text-align: start;
  }
  .sect-groups {
    flex-direction: column;
    align-items: center;
  }
  .group2 {
    text-align: start;
    flex-direction: column;
    align-items: center;
  }
  .div-group2 {
    margin: 10px 0;
    background-color: #f3f3f3;
    width: 100%;
    padding: 30px;
  }

  .table {
    font-size: 8px;
    font-weight: bold;
    line-height: 20px;
    margin: 0 auto;
  }

  .table > tbody > tr > td {
    padding: 0.2rem 0.2rem;
  }
}

@media (min-width: 450px) {
  .table {
    font-size: 12px;
    line-height: 30px;
    font-weight: normal;
  }

  .table > tbody > tr > td {
    padding: 0.5rem 0.5rem;
  }
}

@media (max-width: 768px) {
  .c-init {
    flex-direction: column-reverse;
  }
  .p-text {
    font-size: 16px;
    line-height: 30px;
  }

  .div-image,
  .c-init__image {
    margin: 0;
    padding: 15px 0 0 0;
    width: 100%;
    text-align: center;
  }
  .image {
    width: 300px;
  }
  .c-init__text {
    margin-top: 3rem;
  }
}

@media (min-width: 769px) {
  .c-init {
    flex-direction: column-reverse;
  }
  .image {
    width: 320px;
  }
  .div-image,
  .c-init__image {
    /* padding-left: 70px; */
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 15px 0 0 0;
    width: 100%;
    /* text-align: center; */
  }
  .c-init__text {
    padding-left: 12px;
    width: 50%;
  }

  .table {
    font-size: 16px;
  }
}
.caminho-ouro {
  width: 80vw !important;
}
@media (max-width: 1024px) {
  .sect {
    width: 100%;
    padding: 0 12px;
  }
}
@media (min-width: 1024px) {
  .sect-groups {
    flex-direction: row;
    align-items: center;
  }
  .div-groups {
    border: 2px solid #9a9a9a;
    width: 22%;
    padding: 20px 15px;
    text-align: start;
    /* height: 440px; */
  }

  .group2 {
    flex-direction: row;
    padding: 0px 60px;
  }
  .div-group2 {
    background-color: #f3f3f3;
    width: 47%;
    padding: 30px;
    height: 820px;
  }
  .c-init__image,
  .image {
    width: 458px;
    margin-left: 50px;
  }
  .c-init__text {
    margin-top: 3rem;
  }
  .c-init {
    flex-direction: row;
  }
  .div-image,
  .c-init__image {
    display: block;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .p-div {
    padding: 0px 0px 0px 140px;
  }
  .sectionInit {
    margin-top: 5rem !important;
    padding: 0 110px;
  }
  .art-s {
    margin: 0 74.5px;
    padding: 5px;
  }
  .div-image {
    padding-top: 15px;
  }
  /* .c-init__image .image {
    width: 760px;
  } */
}
@media (max-width: 425px) {
  .div-line img {
    width: 290px;
  }
}
