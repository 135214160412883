@media (min-width: 320px) {
  #autorizado .items {
    margin-top: 3rem!important;
    padding: 3rem 1rem !important;
    border: 1px solid #dee2e6!important;
  }
}
@media (min-width: 400px) {
  #autorizado .items {
    padding: 3rem !important;
  }
}
